import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import './ProjetId.css';
import firebase from "firebase";
import 'react-awesome-slider/dist/styles.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";


export default function Projets() {
    const tmpPathname = window.location.pathname.split('/')
    const [projects, setProjects] = useState([]);
    const [projectsLeft, setProjectsLeft] = useState(null);
    const [projectsRight, setProjectsRight] = useState(null);
    const [imgs, setImgs] = useState([]);
    const pathname = `${tmpPathname[1]}/${tmpPathname[3]}`
    const category = `${tmpPathname[2].split('%20').join(' ')}`
    const [filteredProjects, setFilteredProject] = useState(null)
    const [lightBox, setLightBox] = useState(false)
    const [toggler, setToggler] = useState(false)

    const maxMeight = .6*window.innerHeight;
    const [photoIndex, setPhotoIndex] = useState(0)

    const dbFirebase = useRef(null);

    useEffect(() => {
        setProjectsLeft(null)
        setProjectsRight(null)
        setImgs([])

        dbFirebase.current = firebase.database().ref('/projets').get().then((snapshot) => {
            if (snapshot.exists) {
                const data = snapshot.val()

                const dataToArray = Object.keys(data).map(key => {
                    return {
                        id: key,
                        ...data[key]
                    }
                })
                // eslint-disable-next-line
                setProjects(dataToArray.sort((a,b)=>a.year < b.year ? 1 : -1).filter((r) => {
                    if (r.category === category) {
                        if (r.id === tmpPathname[3]){
                            // eslint-disable-next-line
                            setFilteredProject(r)
                            // eslint-disable-next-line
                            Object.keys(r.listimgs).map((id) => {
                                if (r.listimgs[id].url !== r.imgs) {
                                    setImgs(prec=>[...prec, {
                                        id: id,
                                        image: r.listimgs[id]
                                    }])
                                }
                            })
                            
                        }
                        return r;
                    }
                }))   
            }
        }).then(()=>setToggler(true))  
        // eslint-disable-next-line   
    }, [pathname])

    if(toggler){
        setToggler(false)
        // eslint-disable-next-line
        projects.filter((r,i) => {
            if(r.id === filteredProjects.id) {
                if (i > 0)
                    setProjectsLeft( projects[i-1]);
            }
        })
        // eslint-disable-next-line
        projects.filter((r,i) => {
            if(r.id === filteredProjects.id) {
                if (i < projects.length -1)
                    setProjectsRight( projects[i + 1])
            }
        }) 
    }
    

    const closeLightBox = () =>{
        setLightBox(false)

    }

    const openLightBox =(index) => {
        setPhotoIndex(index)
        setLightBox(true)
    }

    return (
        projects !== null ?
            <div>
                {lightBox ? <Lightbox
                    mainSrc={imgs[photoIndex].image.url}
                    nextSrc={imgs[(photoIndex + 1) % imgs.length]}
                    prevSrc={imgs[(photoIndex + imgs.length - 1) % imgs.length]}
                    onCloseRequest={() => closeLightBox()}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + imgs.length - 1) % imgs.length)}
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % imgs.length )}
                    enableZoom={false}

                />: ''}
                <div className="">
                    <div className="grid justify-center">
                        {filteredProjects ? <div>
                            <div style={{height: maxMeight, width: '90vw', justifySelf: 'center', marginBottom: '2rem', backgroundImage: `url(${filteredProjects.imgs})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}/>
                        <div style={{width: '90vw', justifySelf: 'center'}} className='grid grid-cols-1 lg:grid-cols-2'>
                            <div className='pt-4 lg:pt-10'>
                                <h1 className='text-4xl text-dhpaysage font-medium text-left '>{filteredProjects.name}</h1>
                                <div style={{height: '2px', backgroundColor: 'black', margin: '1rem 0'}}/>
                                <p className='grid col-span-2 text-justify space-white'>{filteredProjects.desc}</p>
                            </div>
                            <div className='grid mt-4 sm:mt-18 lg:ml-4 lg:pt-10'>
                                <div className='flex items-center'>
                                    <h2 className='font-bold text-sm py-1 pr-1'>CATEGORIE : </h2>
                                    <p className='text-sm font-normal'> {filteredProjects.category}</p>
                                </div>
                                <div className='flex items-center'>
                                    <h2 className='font-bold text-sm py-1 pr-1'>CLIENT :</h2> 
                                    <p className='text-sm font-normal'> {filteredProjects.client}</p>
                                </div>
                                <div className='flex items-center'>
                                    <h2 className='font-bold text-sm py-1 pr-1'>LOCALISATION : </h2>
                                    <p className='text-sm font-normal'> {filteredProjects.localisation}</p>
                                </div>
                                <div className='flex items-center'>
                                    <h2 className='font-bold text-sm py-1 pr-1'>ANNEE : </h2>
                                    <p className='text-sm font-normal'> {filteredProjects.year}</p>
                                </div>
                            </div>
                        </div>
                        </div> :''}
                        <div className='grid grid-cols-2 md:grid-cols-3 gap-2 mt-6' style={{width: '90vw'}}>
                            {imgs.sort((a,b) => a.id - b.id).map((img, index) =><div key={index} style={{height: '20vw'}}> <img className='object-cover w-full h-full cursor-pointer' src={img.image.url} alt={img.image.name} onClick={()=>openLightBox(index)}/></div>
                            )}

                        </div>
                    </div>
                </div>
                {projectsLeft!== null ?
                    <div className='hidden sm:block'>
                        <Link className=' fixed left-0 top-1/2 transform -transition-y-1/2 extendLinkLeft' to={('/projets/'+projectsLeft.category+'/'+projectsLeft.id)}>
                            <FontAwesomeIcon style={{height: '100px', width:'20px'}} className='z-10 relative bg-gray-400 text-white' icon={faAngleLeft} size="2x" />
                            <div className='absolute cursor-pointer imgExtendLeft transform translate-y-102 z-0' style={{backgroundImage: `url(${projectsLeft.imgs})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}/>
                        </Link>
                    </div>:<div className='hidden'/>}
                {projectsRight !== null ?
                    <div className='hidden sm:block'>
                        <Link className=' fixed right-0 top-1/2 transform extendLinkRight -transition-y-1/2' to={('/projets/'+projectsRight.category+'/'+projectsRight.id)}>
                            <FontAwesomeIcon style={{height: '100px', width:'20px'}} className='z-10 relative bg-gray-400 text-white' icon={faAngleRight} size="2x" />
                            <div className='absolute cursor-pointer imgExtendRight transform translate-y-102 z-0' style={{backgroundImage: `url(${projectsRight.imgs})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}/>
                        </Link>
                    </div>: <div className='hidden'/>}


            </div>
            : ''
    );

}
