import React, {useState} from "react";
import firebase from "firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router";


export default function AdminAgenceTeam(team, update) {
    const [modifier, setModfier] = useState(false);
    const [object, setObject] = useState([]);
    const [objectKey, setObjectKey] = useState([]);
    const [add, setAdd] = useState(false);
    const [inputs, setInputs] = useState([]);
    const [inputsUpdate, setInputsUpdate] = useState([]);
    const [img, setImg] = useState(null);
    const [saveMemberButton, setsaveMemberButton] = useState(false);
    const [updateMemberButton, setupdateMemberButton] = useState(false);
    const [deleteMemberButton, setdeleteMemberButton] = useState(false);
    const history = useHistory();

    const clickAdd = () => {
        setModfier(false)
        setAdd(!add)
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
    }
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    const addPerson = () => {
        handleUpload()
    }

    const modif = (person, index) => {
        setModfier(true)
        setAdd(false)
        setObject(person)
        setObjectKey(Object.keys(team)[index])
    }

    const handleChange = (e) => {
        setImg(e.target.files)
    }

    const handleChangeUpdate = (e) => {
        setImg(e.target.files)
    }


    const handleUpload = () => {
        img !== null ?
            Object.values(img).map((img) => firebase.storage()
                .ref(`agence/team/${img.name}`)
                .put(img)
                .on("state_changed", (snapshot) => {
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');

                    }, (error) => {
                        console.log(error)
                    },
                    () => {
                        firebase.storage().ref(`agence/team/${img.name}`)
                            .put(img).snapshot.ref.getDownloadURL()
                            .then((downloadURL) => {
                                firebase.database().ref().child('/agence/team')
                                    .push({...inputs, 'imgprofil': downloadURL})
                            })
                            .catch(setsaveMemberButton(true))
                            .catch(setTimeout(() => {
                                setsaveMemberButton(false)
                            }, 3000))
                            .catch(setTimeout(() => {
                                history.push("/admin")
                            }, 3500))
                    })
            ) : firebase.database().ref().child('/agence/team')
                .push({...inputs, 'imgprofil': ''})

                .catch(setsaveMemberButton(true))
                .catch(setTimeout(() => {
                    setsaveMemberButton(false)
                }, 3000))
                .catch(setTimeout(() => {
                    history.push("/admin")
                }, 3500))

    }

    const handleUpdate = (event) => {
        event.persist();
        setInputsUpdate(inputsUpdate => ({...inputsUpdate, [event.target.name]: event.target.value}));
    }

    const updateData = () => {
        img !== null ?
            Object.values(img).map((img) => firebase.storage()
                .ref(`agence/team/${img.name}`)
                .put(img)
                .on("state_changed", (snapshot) => {
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');

                    }, (error) => {
                        console.log(error)
                    },
                    () => {
                        firebase.storage().ref(`agence/team/${img.name}`)
                            .put(img).snapshot.ref.getDownloadURL()
                            .then((downloadURL) => {
                                firebase.database().ref(`/agence/team/${objectKey}`)
                                    .update({...inputsUpdate, 'imgprofil': downloadURL})
                            })
                            .catch(setupdateMemberButton(true))
                            .catch(setTimeout(() => {
                                setupdateMemberButton(false)
                            }, 3000))
                            .catch(setTimeout(() => {
                                history.push("/admin")
                            }, 3500))
                    })
            ) : firebase.database().ref(`/agence/team/${objectKey}`)
                .update(inputsUpdate)
                .catch(setupdateMemberButton(true))
                .catch(setTimeout(() => {
                    setupdateMemberButton(false)
                }, 3000))
                .catch(setTimeout(() => {
                    history.push("/admin")
                }, 3500))
    }

    const deleteMember = () => {
        firebase.database().ref()
            .child(`/agence/team/${objectKey}`)
            .remove()
            .catch(history.push("/admin"))
    }

    return (
        <div>
            <div className='p-5'>

                {update === 1 ?
                    <div >
                        <div className="p-5 grid grid-cols-3 gap-4 ">
                            <button className="admin-agence-toogler rounded-xl focus:outline-none focus:bg-blue-100"
                                    onClick={() => clickAdd()}>
                                <div
                                    className="admin-agence-toogler rounded-xl border border-blue-400 hover:bg-blue-50">
                                    <FontAwesomeIcon
                                        className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400"
                                        icon={faPlus} size="2x"/>
                                    <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">Ajouter</p>
                                </div>
                            </button>

                        </div>
                        <div className="md:grid xl:grid-cols-5 md:grid-cols-3 flex flex-col">
                            {team !== undefined ? Object.values(team).map((teamone, index) => 
                                <div key={index} className='grid'>
                                    <button key={index}
                                            className="admin-agence-toogler rounded-xl focus:outline-none focus:bg-blue-100"
                                            onClick={() => modif(teamone, index)}>
                                        <div
                                            className="admin-agence-toogler rounded-xl border border-blue-400 hover:bg-blue-50">
                                            <img src={teamone.imgprofil} className='rounded-xl' alt=''/>
                                            <FontAwesomeIcon
                                                className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400"
                                                icon={faPlus} size="2x"/>
                                            <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">Modifier</p>
                                        </div>
                                    </button>
                                </div>)
                            :""}
                        </div>

                    </div> : ''}


                {add === false ? '' : <form className='grid grid-cols-4 gap-4' onSubmit={handleSubmit}>
                    <div className='grid'>
                        <label>Nom</label>
                        <input type="text" className='inputBox' onChange={handleInputChange} name="nom"
                               value={inputs.nom}
                               placeholder='Nom'/>
                    </div>
                    <div className='grid'>
                        <label>Prenom</label>
                        <input type="text" className='inputBox' onChange={handleInputChange} name="prenom"
                               value={inputs.prenom}
                               placeholder='Prénom'/>
                    </div>
                    <div className='grid'>
                        <label>Metier</label>
                        <input type="text" className='inputBox' onChange={handleInputChange} name="metier"
                               value={inputs.metier}
                               placeholder='Métier'/>
                    </div>
                    <div className='grid'>
                        <label>Image de profil</label>
                        <input type="file" className='inputBox' onChange={handleChange} name="imgprofil"/>
                    </div>

                    <div className='col-span-4 grid grid-cols-5 p-5'>
                        <button
                            className=" col-start-3 px-4 py-1 text-green-600 font-semibold rounded-full border border-green-200 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none"
                            onClick={() => addPerson()} type="submit">Ajouter
                        </button>
                        {saveMemberButton ? <div
                            className="z-50 fixed top-0 left-0 w-screen border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                            <p className='flex justify-center'>Le membre a bien été ajouté !</p>
                        </div> : ''}
                    </div>
                </form>}


                <div>
                    {modifier === false ? '' : <form className='grid grid-cols-4 gap-4' onSubmit={handleSubmit}>
                        <div className='grid'>
                            <label>nom</label>
                            <input type="text" className='inputBox' onChange={handleUpdate} name="nom"
                                   value={inputs.nom}
                                   placeholder={object.nom}/>
                        </div>
                        <div className='grid'>
                            <label>Prenom</label>
                            <input type="text" className='inputBox' onChange={handleUpdate} name="prenom"
                                   value={inputs.prenom}
                                   placeholder={object.prenom}/>
                        </div>
                        <div className='grid'>
                            <label>Metier</label>
                            <input type="text" className='inputBox' onChange={handleUpdate} name="metier"
                                   value={inputs.metier}
                                   placeholder={object.metier}/>
                        </div>
                        <div className='grid'>
                            <label>Image de profil</label>
                            <input type="file" className='inputBox' onChange={handleChangeUpdate} name="imgprofil"/>
                        </div>

                        <div className='col-span-4 grid grid-cols-5 p-5'>
                            <button
                                className="grid col-start-2 px-4 py-1 text-green-600 font-semibold rounded-full border border-green-200 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none"
                                onClick={() => updateData()} type="button">Modifier
                            </button>
                            {updateMemberButton ? <div
                                className="z-50 fixed top-0 left-0 w-screen border border-t-0 border-green-400 rounded-b bg-green-100 px-4 py-3 text-green-700">
                                <p className='flex justify-center'>Le membre a bien été modifié !</p>
                            </div> : ''}
                            <button
                                className="grid col-start-4 px-4 py-1 text-red-600 font-semibold rounded-full border border-red-200 hover:text-white hover:bg-red-600 hover:border-transparent focus:outline-none"
                                onClick={() => setdeleteMemberButton(true)}>Supprimer le membre
                            </button>
                            {deleteMemberButton ?
                                <div className='z-50 fixed top-0 left-0 w-screen' role="alert">
                                    <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                                        Danger
                                    </div>
                                    <div
                                        className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                                        <p>Voulez vous rellement supprimer le membre ?</p>
                                        <button
                                            className="px-4 py-1 text-red-600 font-semibold rounded-full border border-red-200 hover:text-white hover:bg-red-600 hover:border-transparent focus:outline-none"
                                            onClick={() => deleteMember()}>Supprimer le membre
                                        </button>
                                        <button
                                            className="px-4 py-1 text-red-600 font-semibold rounded-full border border-red-200 hover:text-white hover:bg-red-600 hover:border-transparent focus:outline-none"
                                            onClick={() => setdeleteMemberButton(false)}>Annuler
                                        </button>
                                    </div>
                                </div> : ''}
                        </div>
                    </form>}
                </div>
            </div>
        </div>
    );
}
