import firebase from 'firebase'
// For FirebaseModule JS SDK v7.20.0 and later, measurementId is optional

const app = firebase.initializeApp({
    apiKey: "AIzaSyC0kcn-0xPCHjFn_4DtA7o8DoIJOHMdWEk",

  authDomain: "dhpaysages-ebc90.firebaseapp.com",

  databaseURL: "https://dhpaysages-ebc90.firebaseio.com",

  projectId: "dhpaysages-ebc90",

  storageBucket: "dhpaysages-ebc90.appspot.com",

  messagingSenderId: "682741780582",

  appId: "1:682741780582:web:f4a86951747f41546fdda7",

  measurementId: "G-DJQ3R7QX25"

});
firebase.analytics().logEvent('notification_received');
export default app;

