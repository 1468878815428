import React, {useRef, useState} from "react";
import firebase from "firebase";
import {useHistory} from "react-router";
import Compressor from 'compressorjs';

export default function NewProject(){
    const [imgs, setImgs] = useState([]);
    const localisation = useRef(null);
    const name = useRef(null);
    const desc = useRef(null);
    const client = useRef(null);
    const category = useRef(null);
    const year = useRef(null);
    const [saveProjectButton, setsaveProjectButton] = useState(false);
    const history = useHistory();


    const addProject = (project) => {
        firebase.database()
            .ref("/projets")
            .push(project)

    }

    const handleSubmit = (e) =>{
        e.preventDefault()
    }

    const projectForm = () => {

        addProject({'name': name.current.value, 'localisation': localisation.current.value, 'desc': desc.current.value, 'client': client.current.value,
        'category': category.current.value, 'year': year.current.value, 'imgs': ''})

        firebase.database().ref("/projets").once("value", snapshot => {
            const longueur = snapshot.numChildren()
            const id =  Object.keys(snapshot.val())[longueur - 1]
            handleUpload(id)

        }).catch(setsaveProjectButton(true))
            .catch(setTimeout(()=>{
            history.push("/adminprojects")
        }, 3000))


    }

    const handleChange = (e) => {
        const images = e.target.files;
        for(let i=0; i<images.length; i++){
            const image = images[i];
            new Compressor(image, {
                quality: 0.5,
                success: (res) => {
                    setImgs(img => [...img, res])
                },
            });
        }

    }

    const handleUpload = (id) => {

        let allImgs = []

        Object.values(imgs).map((img, i)=> {
            firebase.storage()
                .ref(`projets/${id}/${img.name}`)
                .put(img)
                .on("state_changed", (snapshot)=>{
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');

                    },(error) =>{console.log(error)},
                    ()=>{
                        firebase.storage().ref(`projets/${id}/${img.name}`)
                            .put(img).snapshot.ref.getDownloadURL()
                            .then((downloadURL) => {
                                allImgs.push({name: img.name, url: downloadURL})
                                firebase.database()
                                    .ref(`/projets/${id}/listimgs`)
                                    .set(allImgs)
                            });
                    })}
        )
        
    }
    return(
        <div className='flex flex-col items-center justify-between'>
            <h1 className='font-bold text-3xl m-5'>Nouveau Projet</h1>
            <form onSubmit={handleSubmit}>
                <div className='grid grid-cols-3 gap-4'>
                    <div className="grid">
                        <label>Nom</label>
                        <input className='focus:outline-none inputBox' type="text" ref={name} placeholder="Name"/>
                    </div>
                    <div className="grid">
                        <label>Categorie</label>
                        <select className='focus:outline-none inputBox' ref={category} defaultValue={{value: 'default'}} >
                            <option value='default'>Choisir une catégorie</option>
                            <option value="Batiments publics">Bâtiments publics</option>
                            <option value="Commerces & Activites">Commerces & Activités</option>
                            <option value="Logements & Habitats">Logements & Habitats</option>
                            <option value="Concours & Programmes">Concours & Programmes</option>
                            <option value="Espaces Publics">Espaces Publics</option>
                            <option value="Demeures & Chateaux">Demeures & Chateaux </option>
                            <option value="Equipements Sportifs">Equipements Sportifs</option>
                        </select>
                    </div>
                    <div className="grid">
                        <label>Client</label>
                        <input className='focus:outline-none inputBox' type="text" ref={client} placeholder="Client"/>
                    </div>
                    <div className="grid">
                        <label>Localisation</label>
                        <input className='focus:outline-none inputBox' type="text" ref={localisation} placeholder="Localisation"/>
                    </div>
                    <div className="grid">
                        <label>Années</label>
                        <input className='focus:outline-none inputBox' type="text" ref={year} placeholder="Années"/>
                    </div>
                    <div className="grid col-span-3">
                        <label>Description</label>
                        <textarea className='focus:outline-none inputBox space-white' rows="9" ref={desc} placeholder="Description"/>
                    </div>
                    <div className="grid">
                        <label>IMAGES</label>
                        <input type="file" multiple onChange={handleChange} />
                    </div>
                </div>


                <div className='grid grid-cols-3'>
                    <div className="grid col-start-2 p-5">
                        <button className="btn-save" onClick={()=>projectForm()} type="submit">enregistrer</button>
                        {saveProjectButton ? <div
                            className="alert-save">
                            <p className='flex justify-center'>Le projet à bien été enregistré !</p>
                        </div> : ''}
                    </div>
                </div>

            </form>
            <div>
            </div>
        </div>
    )
}
