import React, {useEffect, useRef, useState} from 'react';
import './../Projets/Projets.css';
import firebase from "firebase";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


export default function Agence() {
    const [team, setTeam] = useState([]);
    const [imgs, setImgs] = useState([]);
    const [lightBox, setLightBox] = useState()

    const [photoIndex, setPhotoIndex] = useState(0)
    const dbFirebase = useRef(null);

    useEffect(() => {
        setImgs([])
        dbFirebase.current = firebase.database().ref('/agence').get().then((snapshot) => {
            if (snapshot.exists) {
                setTeam(snapshot.val().team)
            }
        });
        firebase.storage().ref().child(`/agence/agence`).list().then((res) => {
            res.items.forEach((item) => {
                item.getDownloadURL().then((url) => {
                    setImgs(precURL => ([...precURL, url]))
                })
            })
        })

    }, [])


    const closeLightBox = () =>{
        setLightBox(false)
        window.scrollTo(0, 0)
    }
    const openLightBox =(index) => {
        setPhotoIndex(index)
        setLightBox(true)
    }

    const height = window.innerHeight * .33;
    const boxNumber = window.innerWidth < 900 ? window.innerWidth < 500 ? 1 : 2 : 3;
    const sizingGrid = `repeat(${boxNumber}, 1fr)`;


    return (
        <div className="p-5">
            {lightBox ? <Lightbox
                mainSrc={imgs[photoIndex]}
                nextSrc={imgs[(photoIndex + 1) % imgs.length]}
                prevSrc={imgs[(photoIndex + imgs.length - 1) % imgs.length]}
                onCloseRequest={() => closeLightBox()}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + imgs.length - 1) % imgs.length)}
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % imgs.length )}
                enableZoom={false}

            />: ''}
            <h1  className="flex justify-center font-bold text-4xl m-16">Notre Agence</h1>
            <div className='grid' style={{gridAutoColumns: height, gridAutoRows: height, gridTemplateColumns: sizingGrid, gap:'10px'}}>
                {Object.values(imgs).map((img, i) => <img key={i} onClick={()=>openLightBox(i)} style={{objectFit: 'cover', width: '100%', height: '100%'}} src={img} alt="agence D&H"/>)}
            </div>

            <div><h1 className="flex justify-center font-bold text-4xl m-16">Notre Equipe</h1>
                <div
                    className=" justify-items-center items-center grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                    {Object.values(team).sort((a,b)=> (a.nom > b.nom ) ? 1 : -1).map((individu, index) =>
                        <a className='relative' key={index} href="#s">
                            <img style={{objectFit: 'cover', width: '300px', height: '300px'}} src={individu.imgprofil} alt={individu.nom}/>
                            <div className="projets-shadow">
                                <div className="texte"><h2>{individu.prenom} {individu.nom}</h2></div>
                                <div className="texte3"><p>{individu.metier}</p></div>
                            </div>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );

}


