import React, {useEffect, useRef, useState} from 'react';
import './Admin.css';
import firebase from "firebase";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export default function AdminProjects(){
  let [project, setProject] = useState([]);

    const dbFirebase = useRef(null);

    useEffect(() => {
        dbFirebase.current = firebase.database().ref('/projets').get().then((snapshot) => {
            if (snapshot.exists) {
                const data = snapshot.val()

                const dataToArray = Object.keys(data).map(key => {
                    return {
                        id: key,
                        ...data[key]
                    }
                })
                setProject(dataToArray.reverse())
            }
        });
    }, [])

  return (
        <div className={'sizeImgProjets'}>
          <div className='grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 '>
            <Link className="p-5 " to={'/newproject'}>
              <div className="admin-newproject  border border-blue-400 hover:bg-blue-50">
                <FontAwesomeIcon className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400" icon={faPlus} size="2x" />
                <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">Nouveau projet</p>
              </div>
            </Link>

            {Object.values(project).map((projet, index) =>
                <div className="p-5 " key={index}>
                  <Link to={`/adminupdateprojet/${projet.id}`}>
                    <div className="single-img">

                      <img className="" src={(projet).imgs} alt=""/>
                      <div className="projets-shadow ">
                          <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-medium'>Modifier le projet : {projet.name}</div>
                      </div>
                    </div>
                  </Link>
                </div>
            )}

          </div>
        </div>
  );
}
