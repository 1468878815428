import React from 'react';
import './Footer.css';
import {Link} from "react-router-dom";


export default function Footer(){
    const copyright = `${new Date().getFullYear()}`;

    return(
        <div className="footer-div border-t border-dhpaysage">
            <div className="flex justify-center flex-col items-center lg:pb-16 pb-5 lg:flex-row my-16 sm:my-5 ">
                <a className="p-1 lg:p-5 text-gray-600 hover:text-black text-xl lg:text-2xl font-medium" href="https://www.facebook.com/DHPaysages/">Facebook</a>
                <a className="p-1 lg:p-5 text-gray-600 hover:text-black text-xl lg:text-2xl font-medium" href="https://www.instagram.com/dhpaysages/">Instagram</a>
                <a className="p-1 lg:p-5 text-gray-600 hover:text-black text-xl lg:text-2xl font-medium" href="https://www.linkedin.com/company/dh-paysages/">Linkedin</a>
            </div>
            <div className="grid lg:grid-cols-2 sm:pt-5 pt-5">
                <div className="flex flex-col items-center justify-center pb-12 lg:pl-5 md:text-base lg:flex-row lg:align-middle lg:justify-start lg:items-center">
                    <div className="p-1 lg:p-2">
                        <Link to={'/'}>
                            <p>ACCUEIL</p>
                        </Link>
                    </div>
                    <div className="p-1 lg:p-2">
                        <Link to={'/Projets'}>
                            <p>PROJETS</p>
                        </Link>
                    </div>
                    <div className="p-1 lg:p-2">
                        <Link to={'/Agence'}>
                            <p>AGENCE</p>
                        </Link>
                    </div>
                    <div className="p-1 lg:p-2">
                        <Link to={'/contact'}>
                            <p>CONTACT</p>
                        </Link>
                    </div>
                    <div className="p-1 lg:p-2">
                        <Link to={'/news'}>
                            <p>NEWS</p>
                        </Link>
                    </div>
                </div>
                <div className="p-2 flex flex-col items-center justify-center lg:justify-start font-medium lg:flex lg:items-end">
                    <p>©Copyright {copyright} D&H Paysages</p>
                    <Link to={'/login'} className="font-medium">Connexion</Link>
                </div>


            </div>


        </div>
    );
}
