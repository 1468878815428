import React, {useEffect, useRef, useState} from 'react';
import './Home.css';
import firebase from "firebase";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { useHistory } from "react-router-dom";

export default function Home() {
    const [projects, setProjects] = useState([]);
    const dbFirebase = useRef(null);
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const history = useHistory();


    useEffect(() => {
        dbFirebase.current = firebase.database().ref('/projets').get().then((snapshot) => {
            if (snapshot.exists) {
                const data = snapshot.val()

                const dataToArray = Object.keys(data).map(key => {
                    return {
                        id: key,
                        ...data[key]
                    }
                })
                setProjects(dataToArray.sort((a,b)=>a.year < b.year ? 1 : -1))
            }
        });
    }, [])

    const maxHeight = .6*window.innerHeight;

    return (

        <div>
            <div className='grid cursor-pointer'>
                <AutoplaySlider
                    animation="cubeAnimation"
                    play={true}
                    bullets={false}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={6000}
                    infinite={true}
                    mobileTouch={true}
                    style={{height: maxHeight, width: '100%', maxWidth: '100%', justifySelf: 'center'}}
                >
                    {projects.map((projet) => <div onClick={()=>history.push(`/projets/${projet.category}/${projet.id}`)} data-src={projet.imgs}/> )}
                </AutoplaySlider>
            </div>
        </div>

    );
}
