import React, {useContext, useCallback, useState} from "react";
import './Login.css'
import { withRouter, Redirect} from "react-router";
import app from '../FirebaseModule/Database';
import { AuthContext } from "../FirebaseModule/Auth";

import fondConnection from '../../img/wallpaper2.jpg';

const Login = ({ history }) => {
    const [isActive, setActive] = useState(false);
    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { email, password } = event.target.elements;
            try {
                await app
                    .auth()
                    .signInWithEmailAndPassword(email.value, password.value);
                history.push('/admin');
            } catch (error){
                toggleClass()

            }
        },
        // eslint-disable-next-line
        [history]
    );
    const toggleClass = () => {
        setActive(!isActive);
    };

    const { currentUser } = useContext(AuthContext);

    if (currentUser){
        return <Redirect to='/admin' />
    }
    return (


    <div className="font-mono bg-gray-400">
    <div className="container mx-auto">
        <div className="flex justify-center px-6 my-12">
            <div className="w-full xl:w-3/4 lg:w-11/12 flex">
                <div
                    className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
                    style={{ backgroundImage: `url(${fondConnection})`}}
                />
                <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
                    <h3 className="pt-4 text-2xl text-center">D&H Paysages</h3>
                    <form className="px-8 pt-6 pb-8 mb-4 bg-white rounded" onSubmit={handleLogin}>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="username">
                                Email
                            </label>
                            <input
                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                id="email"
                                type="text"
                                placeholder="Email"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="password">
                                Password
                            </label>
                            <input
                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                id="password"
                                type="password"
                                placeholder="******************"
                            />
                            <p className={`${isActive ? 'text-xs italic text-red-500' : 'hidden'}`}>Please choose a password.</p>
                        </div>
                        <div className="mb-6 text-center">
                            <button
                                className="btn-blue transform scale-90"
                                type="submit"
                            >
                                Se Connecter
                            </button>
                        </div>
                        <hr className="mb-6 border-t"/>
                        <div className="text-center">
                            <a
                                className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                href="mailto:contact@rodolphethienard.fr"
                            >
                                Forgot Password?
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
    );
};
export default withRouter(Login);
