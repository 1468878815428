import {useEffect, useRef, useState} from "react";
import './Projets.css';
import firebase from "firebase";
import {Link} from "react-router-dom";


export default function Projets() {
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProject] = useState([])
    const [display, setDisplay] = useState(6);
    const dbFirebase = useRef(null);

    useEffect(() => {
        dbFirebase.current = firebase.database().ref('/projets').get().then((snapshot) => {
            if (snapshot.exists) {
                const data = snapshot.val()

                const dataToArray = Object.keys(data).map(key => {
                    return {
                        id: key,
                        ...data[key]
                    }
                })
                setProjects(dataToArray.sort((a,b)=>a.year < b.year ? 1 : -1))
            }
        });
    }, [])

    const filtre = (categoryName, index) => {
        if (categoryName === '') {
            setFilteredProject(projects)
        } else {
            setFilteredProject(
                projects.filter((r) => {
                    return (r.category === categoryName) ? r : null;
                }))
        }
        setText(index);
        setDisplay(6);
    }


    const height = window.innerHeight * .33;
    const boxNumber = window.innerWidth < 900 ? window.innerWidth < 500 ? 1 : 2 : 3;
    const sizingGrid = `repeat(${boxNumber}, 1fr)`;
    const [text, setText] = useState(-1);
    return (
        <div className='p-5 lg:p-10 sizeImgProjets'>
            <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 mb-2 text-center' style={{gap: '10px'}}>
                <h1 className={`cursor-pointer border-b border-gray-600 hover:border-black hover:text-black text-lg ${text === 0 ? 'text-black' : 'text-gray-600'}`} onClick={() => filtre('Demeures & Chateaux', 0)}>Demeures & Chateaux</h1>
                <h1 className={`cursor-pointer border-b border-gray-600 hover:border-black hover:text-black text-lg ${text === 1 ? 'text-black' : 'text-gray-600'}`} onClick={() => filtre('Batiments publics', 1)}>Batiments publics</h1>
                <h1 className={`cursor-pointer border-b border-gray-600 hover:border-black hover:text-black text-lg ${text === 2 ? 'text-black' : 'text-gray-600'}`} onClick={() => filtre('Commerces & Activites', 2)}>Commerces & Activites</h1>
                <h1 className={`cursor-pointer border-b border-gray-600 hover:border-black hover:text-black text-lg ${text === 3 ? 'text-black' : 'text-gray-600'}`} onClick={() => filtre('Logements & Habitats', 3)}>Logements & Habitats</h1>
                <h1 className={`cursor-pointer border-b border-gray-600 hover:border-black hover:text-black text-lg ${text === 4 ? 'text-black' : 'text-gray-600'}`} onClick={() => filtre('Concours & Programmes', 4)}>Concours & Programmes</h1>
                <h1 className={`cursor-pointer border-b border-gray-600 hover:border-black hover:text-black text-lg ${text === 5 ? 'text-black' : 'text-gray-600'}`} onClick={() => filtre('Espaces Publics', 5)}>Espaces Publics</h1>
                <h1 className={`cursor-pointer border-b border-gray-600 hover:border-black hover:text-black text-lg ${text === 6 ? 'text-black' : 'text-gray-600'}`} onClick={() => filtre('Equipements Sportifs', 6)}>Equipements Sportifs</h1>
            </div>


            <div className='grid' style={{gridAutoColumns: height, gridAutoRows: height, gridTemplateColumns: sizingGrid, gap:'10px'}}>
                {filteredProjects.map((projet, index) =>
                        ((display > index) ?
                            <Link className='relative' key={index} to={`/projets/${projet.category}/${projet.id}`}>
                                <img  style={{objectFit: 'cover'}} src={projet.imgs} alt=""/>

                                    <div className="projets-shadow">
                                            <div className="texte"><h1>{projet.name}</h1></div>
                                        </div>



                            </Link> : ''
                        )

                )}
            </div>
            {filteredProjects.length > 6 ? <div className='pt-4 text-center'>
                <button className='btn-classic' onClick={() => setDisplay(display + 3)}>Voir plus</button>
            </div> : ''}
        </div>
    );
}
