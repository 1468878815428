import React, {useCallback, useState} from 'react';
import './Navbar.css';
import {Link} from 'react-router-dom';
import {HamburgerSpin} from 'react-animated-burgers';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import * as Scroll from 'react-scroll';

export default function Navbar() {
    let scroll = Scroll.animateScroll;

    const [isActive, setIsActive] = useState(false)

    const toggleButton = useCallback(
        () => setIsActive(prevState => !prevState),
        [],
    )
    const active = isActive === true ? "active" : '';

    const buttonTop = () => {
        scroll.scrollToTop();
    }

    return (
        <div id="haut" className='grid justify-center'>
            <div className='fixed bottom-0 right-0 m-4 px-1 py-1 bg-black bg-opacity-50 cursor-pointer rounded-full' onClick={buttonTop}>
                    <FontAwesomeIcon className='text-white' size='2x' icon={faAngleUp}/>
            </div>
            <div className='grid grid-cols-2 my-5 lg:my-10' style={{width: '90vw'}}>
                <div className='grid justify-start items-center'>
                    <Link
                        className="text-sm font-bold leading-relaxed inline-block mr-4 whitespace-no-wrap"
                        to={'/'}
                    >
                        <img className='nav-img'
                             src="https://firebasestorage.googleapis.com/v0/b/dhpaysages-ebc90.appspot.com/o/images%2Flogo.png?alt=media&token=a92f492b-cf2b-49dd-a663-69bdf196f57e"
                             alt="Logo DHPaysages"/>

                    </Link>
                </div>
                <HamburgerSpin
                    className='z-50 focus:outline-none grid bg-yellow-300 justify-self-end'
                    barColor="#111"
                    {...{isActive, toggleButton}}
                />
                <div className={`navigation ${active}`}>
                    <div className="links" onClick={() => setIsActive(false)}>
                        <div className="link">
                            <Link to={'/'}>
                                <p>Accueil</p>
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={'/Agence'}>
                                <p>Agence</p>
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={'/Projets'}>
                                <p>Projets</p>
                            </Link>
                        </div>


                        <div className="link">
                            <Link to={'/news'}>
                                <p>News </p>
                            </Link>
                        </div>
                        <div className="link">
                            <Link to={'/contact'}>
                                <p>Contact </p>
                            </Link>
                        </div>
                        <div className="grid lg:grid-cols-3 pt-5">
                            <a className=" px-4 navbar-reseau" href="https://www.facebook.com/DHPaysages/">Facebook</a>
                            <a className="px-4 navbar-reseau" href="https://www.instagram.com/dhpaysages/">Instagram</a>
                            <a className="px-4 navbar-reseau"
                               href="https://www.linkedin.com/company/dh-paysages/">Linkedin</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
