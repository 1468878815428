import React from 'react';
import './Admin.css';
import {Link} from "react-router-dom";
import app from "../FirebaseModule/Database";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouseUser, faNewspaper, faSignOutAlt, faTasks} from "@fortawesome/free-solid-svg-icons";


export default function Admin() {

    return (
        <div className='admin-home'>
            <Link to={'/adminprojects'}>
                <button className="" style={{width: '150px'}}><div className="admin-agence-toogler rounded-xl border border-blue-400 hover:bg-blue-50">
                    <FontAwesomeIcon className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400" icon={faTasks} size="2x" />
                    <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">Projets </p>
                </div></button>
            </Link>
            <Link to={'/AdminNews'}>
                <button className="" style={{width: '150px'}}><div className="admin-agence-toogler rounded-xl border border-blue-400 hover:bg-blue-50">
                    <FontAwesomeIcon className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400" icon={faNewspaper} size="2x" />
                    <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">Actualités </p>
                </div></button>
            </Link>
            <Link to={'/Adminagence'}>
                <button className="" style={{width: '150px'}}><div className="admin-agence-toogler rounded-xl border border-blue-400 hover:bg-blue-50">
                    <FontAwesomeIcon className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400" icon={faHouseUser} size="2x" />
                    <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">Agence </p>
                </div></button>
            </Link>

            <Link to={'/login'} style={{textDecoration: "none"}}>
                <button className="" style={{width: '150px'}} onClick={() => app.auth().signOut()}><div className="admin-agence-toogler rounded-xl border border-blue-400 hover:bg-blue-50">
                    <FontAwesomeIcon className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400" icon={faSignOutAlt} size="2x" />
                    <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">Deconnexion </p>
                </div></button>
            </Link>

        </div>
    )
}

