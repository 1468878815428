import React, {useEffect, useRef, useState} from 'react';
import './Admin.css';
import firebase from "firebase";


export default function AdminNews(){
    let [news, setNews] = useState([]);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const title = useRef(null);
    const desc = useRef(null);
    const dbFirebase = useRef(null);
    const [update, setUpdate] = useState(0)
    const [img, setImg] = useState(null)

    useEffect(() => {
        setNews([])
        dbFirebase.current = firebase.database().ref('/news').get().then((snapshot) => {
            if (snapshot.exists) {
                const data = snapshot.val()

                const dataToArray = Object.keys(data).map(key => {
                    return {
                        id: key,
                        ...data[key]
                    }
                })
                setNews(dataToArray)
            }
        });
    }, [update])


    const deleteProject = (id) => {
        firebase.database()
            .ref(`/news/${id}`)
            .remove()
            .then(setUpdate(update + 1))
    }

    const addProject = () => {
        if (img === null) {
            firebase.database()
            .ref("/news")
            .push({
                'title': title.current.value,
                'desc': desc.current.value,
                'img': null,
                'date': new Date().toLocaleDateString('fr', options)
            })
            .then(setUpdate(update + 1))
        }
        else {
            Object.values(img).map((imgPush)=>
                firebase.storage().ref(`/news/${imgPush.name}`)
                .put(imgPush).snapshot.ref
                .getDownloadURL()
                .then((res)=>{
                    firebase.database()
                        .ref("/news")
                        .push({
                            'title': title.current.value,
                            'desc': desc.current.value,
                            'img': res,
                            'date': new Date().toLocaleDateString('fr', options)
                        })
                        .then(setUpdate(update + 1))
                }))
        }

    }
    const handleChange = (e) => {
        setImg(e.target.files)
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
    }

    return(
        <div className=' flex flex-col items-center justify-between m-3'>
            <p className='text-3xl p-4'>Ajouter une Actualité</p>

            <form className='grid grid-cols-3' onSubmit={handleSubmit}>
                <div className='grid col-start-2 m-2'>
                    <label>Titre</label>
                    <input className='focus:outline-none inputBox ' ref={title} placeholder="Titre"/>
                </div>
                <div className='grid col-span-3'>
                    <label>News</label>
                    <textarea className='focus:outline-none inputBox' rows='5' ref={desc} placeholder="News"/>
                </div>
                <input type="file"  onChange={handleChange}/>
                <button className='btn-save m-2' type="submit" onClick={()=>addProject()}>Ajouter</button>
            </form>
            <div>

        </div>

            <div className="md:grid md:grid-cols-2 flex flex-col-reverse m-5 divide-y divide-gray-300">
                {Object.values(news).map((news2, index) => (
                    <div key={index}>

                        <div className='m-3 md:grid md:grid-cols-3 flex flex-col'> 
                            <div className='grid m-2'>
                                <img style={{height: '200px'}} src={news2.img}/>
                            </div>
                            <div className='grid m-2'>
                                <label className='underline justify-self-center'>Titre</label>
                                <p className='p-2 font-medium self-stretch inputBox'>{news2.title}</p>
                            </div>
                            <div className='grid m-2'>
                                <label className='underline justify-self-center'>Date</label>
                                <p className='p-2 self-stretch font-medium inputBox'>{news2.date}</p>
                            </div>
                        </div>

                        <div className={"m-3"}> 
                            <div className='grid col-span-2 m-2'>
                                <label className='underline justify-self-center'>Description</label>
                                <p className='p-2 font-medium self-stretch inputBox'>{news2.desc}</p>
                            </div>

                            <div className='col-start-5 justify-self-end m-2'>
                                <button className='btn-delete' onClick={() => deleteProject(news2.id)}>Supprimer</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
