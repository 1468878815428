import React, {useEffect, useRef, useState} from "react";
import firebase from "firebase";
import {useHistory} from "react-router";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function AdminUpdateProjet(){
    let pathname = window.location.pathname.slice(19);
    const [img, setImg] = useState([]);
    const [imgs, setImgs] = useState([]);
    const [projet, setProjet] = useState([]);
    const [toogler, setToggler] = useState(true);
    const localisation = useRef(null);
    const name = useRef(null);
    const desc = useRef(null);
    const client = useRef(null);
    const category = useRef(null);
    const year = useRef(null);
    const img2 = useRef(null);
    const [deleteProjectButton, setdeleteProjectButton] = useState(false);
    const [saveProjectButton, setsaveProjectButton] = useState(false);
    const history = useHistory();

    useEffect(( ) => {
        if(toogler){
            getUserData();
            setToggler(false)
        }
    },[toogler])
        
    const deleteImage = (id, currentImage) => {
        let t = img.splice(currentImage, 1)[0]
        let final = []
        for (let i=0; i<img.length; i++) {
            final.push(img[i].images)
        }
        console.log(final);
        firebase.database()
            .ref().child(`/projets/${id}/listimgs`)
            .set(final)
        
        setToggler(true)
            
    }

    const deleteProject = (id) => {
        firebase.database()
            .ref().child(`/projets/${id}`)
            .remove();

        firebase.storage().ref(`projets/${id}`).listAll().then(item =>{
            item.items.forEach(imgItem =>{
                firebase.storage().ref().child(`projets/${id}/${imgItem.name}`)
                    .delete();
            })
        }).catch( history.push("/adminprojects"))

    }

    const getUserData = () => {
        firebase.database().ref(`/projets/${pathname}`).get().then(snapshot => {
            setProjet(snapshot.val())
            if (snapshot.val().listimgs){
                const data = snapshot.val().listimgs
                const dataToArray = Object.keys(data).map((key, i) => {
                    return {
                        id: key,
                        images: data[i]
                    }
                })
                setImg(dataToArray)
            }
        });
    };

    const handleChange = (e) => {
        setImgs(e.target.files)
    }

    const handleUpload = () => {
        let allImgs = []
        img.map(r => {
            allImgs.push(r.images)
        })
        Object.values(imgs).map((imgItem)=> firebase.storage()
            .ref(`projets/${pathname}/${imgItem.name}`)
            .put(imgItem)
            .on("state_changed", (snapshot)=>{
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');

                },(error) =>{console.log(error)},
                ()=>{ 
                    
                    firebase.storage().ref(`/projets/${pathname}/${imgItem.name}`)
                    .put(imgItem).snapshot.ref.getDownloadURL()
                            .then((downloadURL) => {
                                allImgs.push({name:imgItem.name, url: downloadURL})
                                firebase.database()
                                    .ref(`/projets/${pathname}/listimgs`)
                                    .update(allImgs)
                            });

                }))
    }

    const updateProject = (project) => {
        firebase.database()
            .ref(`/projets/${pathname}`)
            .update(project)
            .catch(setsaveProjectButton(true)).catch(setTimeout(()=>{
                setsaveProjectButton(false)
        }, 3000))

    }

    const projectForm= () => {
        let final = []
        for (let i=0; i<img.length; i++) {
            final.push(img[i].images)
        }

        updateProject({'name': name.current.value, 'localisation': localisation.current.value, 'desc': desc.current.value, 'client': client.current.value,
            'category': category.current.value, 'year': year.current.value, 'imgs': img2.current.value, 'listimgs': final});
        if(imgs !== []) handleUpload();
        

    }

    const handleSubmit = (e) => {
            e.preventDefault();

    }

    const handleOnDragEnd = (result) => {
        const items = Array.from(img);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem)
        setImg(items)
    }

    const range = (pathname) => {

        let listImgs = []

        firebase.storage().ref(`/projets/${pathname}`)
            .listAll().then(r=> r.items.forEach((e) => {
                e.getDownloadURL().then(res => {
                    listImgs.push({name: e.name, url: res})
                    firebase.database()
                        .ref(`/projets/${pathname}/listimgs`)
                        .set(listImgs)
                })
            }))
    }

    return(
            <div>
                <div className='bg-blue-50 flex flex-col items-center justify-between'>
                    <form className="md:grid md:grid-cols-3 flex flex-col gap-4" onSubmit={handleSubmit}>
                        <div className="grid">
                            <label>Nom du projet</label>
                            <input className="p-2 focus:outline-none inputBox" type="text" ref={name} defaultValue={projet.name}/>
                        </div>
                        <div className="grid">
                            <label>Annee</label>
                            <input className="p-2 focus:outline-none inputBox" type="text" ref={year} defaultValue={projet.year}/>
                        </div>
                        <div className="grid">
                            <label>Localisation</label>
                            <input className="p-2 focus:outline-none inputBox" type="text" ref={localisation} defaultValue={projet.localisation}/>
                        </div>
                        <div className="grid">
                            <label>Nom du Client</label>
                            <input className="p-2 focus:outline-none inputBox" type="text" ref={client} defaultValue={projet.client}/>
                        </div>
                        <div className="grid">
                            <label>Nom de la catégorie</label>
                            <select className="p-2 focus:outline-none inputBox" ref={category} defaultValue={projet.category}>
                                <option value={projet.category}>{projet.category}</option>
                                <option value="Batiments publics">Batiments publics</option>
                                <option value="Commerces & Activites">Commerces & Activites</option>
                                <option value="Logements & Habitats">Logements & Habitats</option>
                                <option value="Concours & Programmes">Concours & Programmes</option>
                                <option value="Espaces Publics">Espaces Publics</option>
                                <option value="Demeures & Chateaux">Demeures & Chateaux </option>
                                <option value="Equipements Sportifs">Equipements Sportifs</option>
                            </select>
                        </div>
                        <div className="grid">
                            <label>Image de présentation</label>
                            <select className="p-2 admin-select-menu focus:outline-none inputBox" ref={img2} defaultValue={projet.imgs}>
                                <option value={projet.imgs}>Ne pas changer l'image</option>
                                {img.sort().map((img3,index) =>
                                    <option key={index} value={img3.images.url}>image: {index} </option>
                                )}
                            </select>
                        </div>
                        <div className="grid ">
                            <label>Importer des images</label>
                            <input type="file" multiple onChange={handleChange}/>

                        </div>
                        <img className="admin-select col-start-2 grid justify-self-center" src={projet.imgs} alt=""/>
                        <div className="grid col-span-3 ">
                            <label>Description</label>
                            <textarea className="focus:outline-none inputBox" rows="9" ref={desc} defaultValue={projet.desc}/>
                        </div>
                    </form>
                </div>
                <div className="grid grid-cols-4 p-10">
                    <div className="grid col-start-2">
                        <button className="btn-save" onClick={()=>projectForm()} type="submit">Sauvegarder</button>
                        {saveProjectButton ? <div
                                className="alert-save">
                                <p className='flex justify-center'>Le projet à bien été sauvegardé !</p>
                        </div> : ''}
                    </div>

                    <div className="grid">
                        <button className="btn-delete" onClick={()=>setdeleteProjectButton(true) }>Supprimer le projet</button>
                        {deleteProjectButton ? <div className='z-50 fixed top-0 left-0 w-screen' role="alert">
                            <div className="alert-delete">
                                Danger
                            </div>
                            <div
                                className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                                <p >Voulez vous rellement supprimer le projet ?</p>
                                <button className="btn-delete" onClick={()=>deleteProject(pathname)}>Supprimer le projet</button>
                                <button className="btn-delete" onClick={()=>setdeleteProjectButton(false)}>Annuler</button>
                            </div>
                        </div> :''}
                    </div>

                </div>

                {/* <button onClick={()=>range(pathname)}>RANGE</button> */}

                <div className='bg-blue-50'> 
                {img ? <DragDropContext  onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="images">
                        {(provided) =>(
                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                                {img.map((element, index) => 
                                        <div className={'flex justify-center gap-3 m-3 border'} key={index}>
                                    <Draggable key={element.id} draggableId={element.id} index={index} >
                                        {(provided)=>(
                                        <li className='grid' {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <div className=' grid grid-cols-2 justify-self-center'>
                                                <div className='self-center justify-self-center'>{element.id}</div>
                                                <img className='self-center admin-update-img' src={element.images.url}/>
                                            </div>
                                        </li>
                                        )}
                                    </Draggable>
                                        <button onClick={()=>deleteImage(pathname, index)}>SUPP</button>
                                    </div>
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext> :''}

                </div>
            </div>
    );
}

