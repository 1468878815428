import React, {useEffect, useRef, useState} from 'react';
import './Admin.css';
import firebase from "firebase";
import AdminAgenceTeam from "./AdminAgenceTeam";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage, faPeopleArrows} from "@fortawesome/free-solid-svg-icons";

export default function AdminNews(){
    const [team, setTeam] = useState(undefined);
    const [updateTeam, setUpdateTeam] = useState(0);
    const [agence, setAgence] = useState([]);
    const dbFirebase = useRef(null);
    const [imgs, setImgs] = useState([])
    const [displayUpdate, setDisplayUpdate] = useState(0)
    const [imgName, setImgName] = useState([]);



    useEffect(() => {
        setAgence([])
        dbFirebase.current = firebase.database().ref('/agence').get().then((snapshot) => {
            if (snapshot.exists) {
                const data = snapshot.val()
                if (data !== null)
                    setTeam(data.team)
            }
        });

        firebase.storage().ref().child(`agence/agence`).list().then((res) => {
            res.items.forEach((item) => {
                setImgName(names => ([...names, item.name]))
                item.getDownloadURL().then((url) => {
                    setAgence(precURL => ([...precURL, url]))
                })
            })
        })
    }, [displayUpdate])

    const handleChange = (e) => {
        setImgs(e.target.files)
    }

    const deleteImage = (currentImage) => {
        firebase.storage().ref().child(`agence/agence/${currentImage}`)
            .delete()
            .then(setDisplayUpdate(displayUpdate + 1))
            .catch(function (error) {
                console.log(error)
            })
    }

    const handleUpload = () => {
        Object.values(imgs).map((img)=> firebase.storage()
            .ref(`agence/agence/${img.name}`)
            .put(img)
            .on("state_changed", (snapshot)=>{
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');

                },(error) =>{console.log(error)},
                ()=>{ alert( 'OUIIIII ')
                })
        )
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
    }

    return(
        <div className='p-16 sizeImgProjets'>
            <div className='sm:grid sm:grid-cols-2 flex flex-col gap-4'>
                <button className="admin-agence-toogler rounded-xl focus:outline-none " onClick={()=> updateTeam === 1 ? setUpdateTeam(0) : setUpdateTeam(1)}>
                    <div className="admin-agence-toogler rounded-xl border border-blue-400 hover:bg-blue-50">
                        <FontAwesomeIcon className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400" icon={faPeopleArrows} size="2x" />
                        <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">L'équipe</p>
                    </div>
                </button>

                <button className="admin-agence-toogler rounded-xl focus:outline-none " onClick={()=> updateTeam === 2 ? setUpdateTeam(0) : setUpdateTeam(2)}>
                    <div className="admin-agence-toogler rounded-xl border border-blue-400 hover:bg-blue-50">
                        <FontAwesomeIcon className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-400" icon={faImage} size="2x" />
                        <p className="absolute left-1/2 top-1/2 transform -translate-x-1/2 translate-y-1/2 text-blue-400">Image de l'agence </p>
                    </div>
                </button>
            </div>
            {AdminAgenceTeam(team, updateTeam)}
            {updateTeam === 2 ? <div>
                <form onSubmit={handleSubmit} className='flex justify-center '>
                    <input type="file" className='inputBox' onChange={handleChange} multiple/>
                    <button type="submit" className='btn-save' onClick={()=> handleUpload()}>Enregistrer</button>
                </form>
                <div className='flex flex-row justify-center flex-wrap m-2'>
                    {agence.map((img, index) =><div key={index} className='flex flex-col'>
                    <img className='m-1' style={{width: '300px', height: '200px', objectFit: 'cover'}} src={img} alt={img}/>
                    <button className='btn-delete justify-center mx-1' onClick={()=>deleteImage(imgName[index])}>Supprimer l'image</button>
                    </div>

                )}
                </div>
            </div>
                :''}
        </div>

    );

}





