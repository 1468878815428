import React from 'react';
import Home from './component/Home/Home';
import Navbar from "./component/Navbar/Navbar";
import Contact from "./component/Contact/Contact";
import Agence from "./component/Agence/Agence";
import News from './component/News/News';
import Projets from './component/Projets/Projets';
import ProjetId from './component/ProjetId/ProjetId';
import AdminProjects from './component/Admin/AdminProjects';
import Admin from './component/Admin/Admin'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import AdminNews from "./component/Admin/AdminNews";
import Login from "./component/Login/Login";
import {AuthProvider} from "./component/FirebaseModule/Auth";
import Footer from "./component/Footer/Footer";
import AdminAgence from "./component/Admin/AdminAgence"
import PrivateRoute from "./PrivateRoute";
import AdminUpdateProjet from "./component/Admin/AdminUpdateProjet";
import AdminAgenceTeam from "./component/Admin/AdminAgenceTeam";
import NewProject from "./component/Admin/NewProject";
import TopPage from "./module/TopPage";
import 'react-image-lightbox/style.css';


function App() {
    return (
        <AuthProvider>
            <Router>
                <TopPage>
                    <div>
                        <Navbar/>
                        <Switch>
                            <Route path={"/"} exact component={Home}/>
                            <Route path={"/contact"} component={Contact}/>
                            <Route path={"/agence"} component={Agence}/>
                            <Route path={"/news"} component={News}/>
                            <Route path={"/projets"} exact component={Projets}/>
                            <Route path={"/projets/:id"} component={ProjetId}/>
                            <PrivateRoute path={"/admin"} exact component={Admin}/>
                            <PrivateRoute path={"/adminprojects"} exact component={AdminProjects}/>
                            <PrivateRoute path={"/adminNews"} exact component={AdminNews}/>
                            <PrivateRoute path={"/adminagence"} exact component={AdminAgence}/>
                            <PrivateRoute path={"/adminupdateprojet/:id"} component={AdminUpdateProjet}/>
                            <PrivateRoute path={"/adminAgenceTeam"} component={AdminAgenceTeam}/>
                            <PrivateRoute path={"/newproject"} component={NewProject}/>
                            <Route path={"/login"} exact component={Login}/>
                            <Route path={"/*"}> <Redirect to="/" /></Route>
                        </Switch>
                        <Footer/>
                    </div>
                </TopPage>
            </Router>
        </AuthProvider>
    );
}

export default App;
