import React from 'react';
import './Contact.css';

export default function Contact(){

    const width = window.innerWidth;

    return(
        <div>
            <div className=" flex justify-center items-center text-center flex-col py-16">
                <h1 className='py-10 text-gray-700 text-3xl'>Vous avez des questions ?</h1>
                <a href="mailto:d.broc@dhpaysages.fr">
                    <button
                        className='btn-svg'>
                        <svg className='w-10 h-10 ' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path
                                d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"/>
                        </svg>
                        <p className='pl-3'>Contactez-nous</p>
                    </button>
                </a>
            </div>


            <div className='grid grid-cols-1 md:grid-cols-2 py-16 px-2 md:px-10'>
                <div className='ml-1'>
                    <h1 className='py-2 font-bold text-xl'>D&H - architecture de paysage</h1>
                    <div className='grid items-center'>


                        <p>18 rue Guynemer</p>
                        <h2>92380 Garches</h2>
                        <p>tel. +33 1 47 01 30 20 </p>
                        <p>fax +33 1 47 01 56 04</p>

                        <a className='text-blue-600 hover:text-blue-800 ' href="mailto:d.broc@dhpaysages.fr">d.broc@dhpaysages.fr</a>
                    </div>
                </div>
                <div className='grid'>
                    
                    <iframe title="garches"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1794.349979013577!2d2.1928517856184992!3d48.84411196689219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67b5b8b274659%3A0xf204c5cd56778c3d!2s18%20Rue%20Guynemer%2C%2092380%20Garches!5e1!3m2!1sfr!2sfr!4v1622997344895!5m2!1sfr!2sfr"
                        width={width > 768 ? width*9.4/20 : width*9.5/10 } height="220" style={{border:'0'}} allowFullScreen="" loading="lazy"/>
                </div>

                <div className=' bg-gray-100 pl-1 mt-2'>
                    <h1 className='py-2 font-bold text-xl '>D&H - Agence Aquitaine</h1>
                    <div className='grid items-center'>

                        <p>1 avenue du Bois du Chevreuil</p>
                        <h2>33610 Cestas</h2>
                        <p>tel. +33 5 56 21 82 22</p>
                        <a className='text-blue-600 hover:text-blue-800' href="mailto:l.ruelloux@dhpaysages.fr">l.ruelloux@dhpaysages.fr</a>
                    </div>
                </div>
                <div className='grid mt-2'>
                    <iframe title="sud"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2702.258406793233!2d-0.6919944845668751!3d44.71642027909921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd54dde82cb3acfd%3A0x488c8d0f9465a0db!2s1%20Avenue%20du%20Bois%20du%20Chevreuil%2C%2033610%20Cestas!5e1!3m2!1sfr!2sfr!4v1622997877050!5m2!1sfr!2sfr"
                        width={width > 768 ? width*9.4/20 : width*9.5/10 } height="220" style={{border:'0'}} allowFullScreen="" loading="lazy"/>
                </div>
            </div>



        </div>
    );
}
