import React, {useEffect, useRef, useState} from 'react';
import './News.css';
import firebase from "firebase";

export default function News() {
    const [news, setNews] = useState([]);
    const [display, setDisplay] = useState(5);
    const dbFirebase = useRef(null);

    useEffect(() => {
        dbFirebase.current = firebase.database().ref('/news').get().then((snapshot) => {
            if (snapshot.exists) {
                const data = snapshot.val()

                const dataToArray = Object.keys(data).map(key => {
                    return {
                        id: key,
                        ...data[key]
                    }
                })
                setNews(dataToArray.reverse())
            }
        });
    })
//todo do the lightBox for each img
    return (
        <div className=' border border-vertSapin '>

            <div
                className=' max-w-3xl mx-auto px-4 sm:px-6 xl:max-w-5xl xl:px-0 bg-white  border-vertSapin border-t-0 border-b-0'>
                <div className='divide-y divide-gray-200 mx-10 '>
                    <div className='pt-6 pb-8 space-y-2 md:space-y-5'>
                        <h1 className='text-3xl leading-9 font-medium text-gray-900 tracking-tight sm:text-4xl sm:leading-10 md:text-6xl md:leading-14'>
                            Les dernières nouveautés</h1>
                    </div>
                    <ul className='divide-y divide-gray-200'>
                        {Object.entries(news).map((news, index) =>
                            display > index ?(
                            <li key={news[0]} className='py-12'>
                                <article className='space-y-2 xl:grid xl:grid-cols-4 xl:space-y-0 xl:items-baseline'>
                                    <dl>
                                        <dd className='text-base leading-6 font-normal text-gray-700'>
                                            {news[1].date}
                                        </dd>
                                    </dl>
                                    <div className='space-y-5 xl:col-span-3'>
                                        <div className='space-y-6'>
                                            <h2 className='text-2xl leading-8 font-medium tracking-tight'>{news[1].title}</h2>
                                            <div className='prose text-justify max-w-none text-gray-700'>
                                                <p>{news[1].desc}</p>
                                            </div>
                                            {news[1].img !== undefined ? <img style={{height: '20vh', width: ''}} src={news[1].img} alt={news[1].title}/>: ''}
                                        </div>

                                    </div>
                                </article>
                                
                            </li>):'')}
                    </ul>
                    <div className='text-center p-2'>
                        <button className='btn-classic' onClick={() => setDisplay(display + 5)}>Voir plus</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
